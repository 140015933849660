import { useState, useEffect } from 'react';

const Countdown = ({ showtime, counterStyle }) => {
  const [countdown, setCountdown] = useState();

  const setTime = () => {
    let timeLeft = Math.abs((showtime - new Date()) / 1000);
    let days = Math.floor(timeLeft / (60 * 60 * 24));
    let hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
    let minutes = ('0' + Math.floor((timeLeft % (60 * 60)) / 60)).slice(-2);
    let seconds = ('0' + Math.floor(timeLeft % 60)).slice(-2);

    if (counterStyle === 'millis') {
      let millis = timeLeft.toFixed(3).slice(-3);
      setCountdown(`${days * 24 + hours}:${minutes}:${seconds}.${millis}`);
      return;
    }

    setCountdown(`${days}days ${hours}h ${minutes}m ${seconds}s`);
  };

  useEffect(() => {
    setTime();

    if (counterStyle === 'millis') {
      const interval = setInterval(setTime, 1000 / 30);
      return () => {
        clearInterval(interval);
      };
    }

    const interval = setInterval(setTime, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className={`countdown countdown-${counterStyle}`}>{countdown}</div>
    </>
  );
};

export default Countdown;
