import { useEffect, useRef, useState } from 'react';
import VideoJS from './components/VideoJS';
import Countdown from './components/Countdown';
import { useParams, useLocation } from 'react-router-dom';

export default function App() {
  const { playerId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [player, setPlayer] = useState();
  const [playerOptions, setPlayerOptions] = useState();
  const [showtime, setShowtime] = useState();
  const [userMessage, setUserMessage] = useState('...');
  const alwaysLiveInterval = useRef(null);

  useEffect(() => {
    (async () => {
      let player;

      try {
        const res = await fetch(
          `https://www.procdn.live/api/getPlayer?player=${playerId}`,
          {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        player = await res.json();
      } catch {
        console.log('Player not found:', playerId);
        setUserMessage('Player not found.');
        return;
      }

      console.log('got Player:', player);

      // old players have no sizing parameter, default was cover:
      player.sizing = player.sizing || 'cover';

      const videoJsOptions = {
        autoplay: player?.mode === 'autoplay' && query.get('preview') === null,
        controls: true,
        controlBar: { fullscreenToggle: true, pictureInPictureToggle: false },
        sources: [
          {
            src: player.source,
            type: player.type,
          },
        ],
        poster: player.poster,
        // liveui: true,
      };

      setPlayerOptions(videoJsOptions);
      setShowtime(new Date(player.showtime._seconds * 1000));
      setPlayer(player);
    })();
  }, [playerId]);

  const handlePlayerReady = (playerInstance) => {
    // you can handle player events here
    playerInstance.on('waiting', () => {
      console.log('player is waiting');
    });

    playerInstance.on('error', (err) => {
      console.log('ERR:', err);
      console.log('video-js ERR:', playerInstance.error());
      if (
        playerInstance.error().code === 2 ||
        playerInstance.error().code === 4
      ) {
        console.log('reloading in 30 seconds...');
        setTimeout(() => {
          console.log('reloading resource');
          playerInstance.src({ type: player.type, src: player.source });
          playerInstance.play();
        }, 30000);
      }
    });

    playerInstance.on('dispose', () => {
      console.log('player will dispose');
    });

    playerInstance.on('play', () => {
      setShowtime(undefined);

      if (player?.delayTarget > 0) {
        alwaysLiveInterval.current = playerInstance.setInterval(() => {
          const currentTime = playerInstance.currentTime();
          const bufferedEnd = playerInstance.bufferedEnd();
          const bufferDelta = bufferedEnd - currentTime;

          if (bufferDelta > player.delayTarget) {
            playerInstance.playbackRate(1.1);
          } else if (bufferDelta < player.delayTarget / 2) {
            playerInstance.playbackRate(0.9);
          } else {
            playerInstance.playbackRate(1);
          }

          console.log({
            currentTime,
            bufferedEnd,
            bufferDelta,
            delayTarget: player.delayTarget,
            playbackRate: playerInstance.playbackRate(),
          });
        }, 1000);
      }
    });

    playerInstance.on('pause', () => {
      console.log('Paused');
      clearInterval(alwaysLiveInterval.current);
    });
  };

  if (player?.mode === 'black') {
    const style = { width: '100%', height: '100%', background: 'black' };
    return (
      <>
        <div style={style}></div>;
        {showtime && player.countdown && (
          <Countdown showtime={showtime} counterStyle={player.countdown} />
        )}
      </>
    );
  }

  return (
    <>
      {player ? (
        <div className={`container ${player.sizing}`}>
          {player.mode === 'poster' ? (
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit:
                  player.sizing === 'force16by9' ? 'fill' : player.sizing,
              }}
              src={player.poster}
              alt=""
            />
          ) : (
            <VideoJS options={playerOptions} onReady={handlePlayerReady} />
          )}
          {showtime && player.countdown && (
            <Countdown showtime={showtime} counterStyle={player.countdown} />
          )}
        </div>
      ) : (
        <h3>{userMessage}</h3>
      )}
    </>
  );
}
